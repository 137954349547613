import React from "react";
import "./styles.css";

const Help = () => {
  const nickname = localStorage.getItem("userNickname");

  return (
    <div className="Main_css">
      <p>준비중~</p>
    </div>
  );
};

export default Help;
