import React from "react";
import "./styles.css";
import Clear_img from './img/Clear_G.jpg'

const clear = () => {

  return (
    <div className="Main_css">
      <img src={Clear_img} width={1080} height={1726}></img>
    </div>
  );
};

export default clear;
