import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";

const Header = () => {
  const name = localStorage.getItem("userName");
  const nickname = localStorage.getItem("userNickname");
  const navigate = useNavigate();
  const logout = () => {
    navigate("/");
  };
  const mainck = () => {
    navigate("/main");
  };
  const buildck = () => {
    navigate("/build");
  };
  const cunningck = () => {
    navigate("/cunning");
  };
  const clearck = () => {
    navigate("/clear");
  };
  const helpck = () => {
    navigate("/help");
  };

  return (
    <div className="Header_css">
      {nickname && (
        <header>
          <ul className="header_li">
            <li>
              <a href="/main" className="li_itemL">
                <button onClick={mainck}>로아와</button>
              </a>
            </li>
            <li>
              <a href="/build" className="li_itemL">
                <button onClick={buildck}>로스트빌드</button>
                
              </a>
            </li>
            <li>
              <a href="/cunning" className="li_itemL">
                <button onClick={cunningck}>컨닝페이퍼</button>
              </a>
            </li>
            <li>
              <a href="/clear" className="li_itemL">
                <button onClick={clearck}>클리어골드</button>
              </a>
            </li>
            <li>
              <a href="/help" className="li_itemL">
                <button onClick={helpck}>알파고~</button>
              </a>
            </li>
            <li className="li_itemR">
              <p>Nickname: {nickname}</p>
            </li>
            <li>
              <button onClick={logout} className="li_itemR">
                로그아웃
              </button>
            </li>
          </ul>
        </header>
      )}
    </div>
  );
};

export default Header;
